import React from 'react';

class price extends React.Component {
    constructor(props) {
      super(props);
      this.state={
      }
    }

    goPrice = () =>  {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.props.history.push('/price');
      }

    render(){
      return (
        <div>
            price
            <button onClick={this.goPrice}>price</button>
        </div>
      );
    }
  }
  
  export default price;
  